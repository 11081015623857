<template>
  <div class="tab-product-info">
    <n-loader :loading="$var('load')" />
    <n-form @submit="submit">
      <n-items>
        <div class="title">
          <h3>Информация компании</h3>
        </div>
        <n-input title="Индекс компании" type="number" v-bind="$form.input('index')" />
        <n-input title="Адрес компании" v-bind="$form.input('address')" />
        <n-input title="Телефон компании" v-bind="$form.input('phone')" />
        <n-input title="Факс компании" v-bind="$form.input('fax')" />
        <n-input title="Почтовый адрес компании" v-bind="$form.input('email')" />
        <n-input title="Должность подписывающего КП" v-bind="$form.input('signerPosition')" />
        <n-input title="ФИО подписывающего КП" v-bind="$form.input('signerName')" />
        <div class="columns">
          <div style="width: 50%">
            <upload-images title="Логотип компании" v-bind="$form.input('logo')" />
          </div>
          <div style="width: 50%">
            <upload-images title="Печать" v-bind="$form.input('print')" />
          </div>
        </div>
      </n-items>
      <n-divide style="margin-top: 30px">
        <div><n-button @click="$emit('close')">Отменить</n-button></div>
        <div><n-button type="submit" color="success">Сохранить</n-button></div>
      </n-divide>
    </n-form>
  </div>
</template>

<script>
export default {
  name: 'TabProductInfo',
  data() {
    return {}
  },
  watch: {
    product() {
      this.init()
    },
  },
  created() {
    this.loadInfo()
  },
  methods: {
    loadInfo() {
      this.$var('load', true)
      $api.app.companyInfo.get().then((response) => {
        this.$form.init(response.data.content[0])
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submit() {
      this.$var('load', true)

      const apis = $n.map([ 'logo', 'print', ], (name) => {
        const file = this.$form.get(name)
        if ($n.isString(file) || !file) {
          return { promise: new Promise((e) => e()), name, }
        }
        return { promise: $api.files.create({ file, }), name, }
      })

      $n.promiseObjects(apis).then((responses) => {
        const files = $n.reduce(responses, (result, item) => {
          result[item.name] = item.response?.data?.content?.id
          return result
        }, {})
        this.save(files)
      }).finally((e) => {
        this.$var('load', false)
      })
    },
    save(files) {
      this.$var('load', true)
      const data = {
        index: this.$form.get('index'),
        address: this.$form.get('address'),
        phone: this.$form.get('phone'),
        fax: this.$form.get('fax'),
        email: this.$form.get('email'),
        signerPosition: this.$form.get('signerPosition'),
        signerName: this.$form.get('signerName'),
        ...files,
      }
      $api.app.companyInfo.edit(this.$form.get('id'), data).then(() => {
        this.$emit('close')
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-product-info {
  position: relative;
  padding-top: 20px;
  margin-top: -1px;

  .columns {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    & + .columns {
      margin-top: calc(var(--n-items-margin) * 2);
    }
    &>*:not(:last-child) {
      margin-right: 20px;
    }
  }
}
</style>
