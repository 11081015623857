<template>
  <div class="currencies">
    <n-card :loading="$var('load')">
      <edit v-if="showEdit" @close="showEdit = false" />
      <div v-else class="container">
        <div class="title">
          <h3>Информация компании</h3>
        </div>
        <div class="tab-product-info">
          <n-loader :loading="$var('load')" />
          <n-form>
            <n-items>
              <n-input title="Индекс компании" type="number" v-bind="$form.input('index')" text />
              <n-input title="Адрес компании" v-bind="$form.input('address')" text />
              <n-input title="Телефон компании" v-bind="$form.input('phone')" text />
              <n-input title="Факс компании" v-bind="$form.input('fax')" text />
              <n-input title="Почтовый адрес компании" v-bind="$form.input('email')" text />
              <n-input title="Должность подписывающего КП" v-bind="$form.input('signerPosition')" text />
              <n-input title="ФИО подписывающего КП" v-bind="$form.input('signerName')" text />
              <div class="columns">
                <div style="width: 50%">
                  <upload-images title="Логотип компании" v-bind="$form.input('logo')" :editable="false" />
                </div>
                <div style="width: 50%">
                  <upload-images title="Печать" v-bind="$form.input('print')" :editable="false" />
                </div>
              </div>
            </n-items>
            <n-divide style="margin-top: 30px">
              <div><n-button @click="showEdit = true">Изменить</n-button></div>
            </n-divide>
          </n-form>
        </div>
      </div>
    </n-card>
  </div>
</template>

<script>
import Edit from './components/editInfo'
export default {
  name: 'TabProductInfo',
  components: {
    Edit,
  },
  props: {
  },
  data() {
    return {
      showEdit: false,
    }
  },
  watch: {
    showEdit() {
      if (!this.showEdit) {
        this.loadInfo()
      }
    },
  },
  created() {
    this.loadInfo()
  },
  methods: {
    loadInfo() {
      this.$var('load', true)
      $api.app.companyInfo.get().then((response) => {
        this.$form.init(response.data.content[0])
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-product-info {
  position: relative;
  padding-top: 20px;
  margin-top: -1px;

  .columns {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    & + .columns {
      margin-top: calc(var(--n-items-margin) * 2);
    }
    &>*:not(:last-child) {
      margin-right: 20px;
    }
  }
}
</style>
